.App {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;

  h2 {
    margin-bottom: 3rem;
  }

  .piece-container {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}
