.piece {
  & + & {
    margin: 1rem;
  }

  .image {
    justify-content: center;
    min-width: 300px;
    width: 40vw;
    object-fit: contain;

    & + & {
      margin: 3rem;
    }
  }
}
